import React from 'react'
import PropTypes from 'prop-types'
import showdown from 'showdown'

showdown.setFlavor('github');
const converter = new showdown.Converter({simpleLineBreaks: 'true'})

const MarkdownContent = ({ content, className }) => {
    let html = converter.makeHtml(content)
    html = html.toString().replace(/\\<br\s?\/>/g, "<br/>")
    return (
  <div className={className} dangerouslySetInnerHTML={{ __html: converter.makeHtml(html) }} />
  )
}

MarkdownContent.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string,
}

export default MarkdownContent