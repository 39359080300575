import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import MarkdownContent from "../components/team/mdxhtmlConverter"

const LineData = (
    ['', 'top-0', 'right-36', '', '']
  )

  export const query = graphql`
query {
  allMdx(filter: {frontmatter: {templateKey: {eq: "privacy"}}}) {
    nodes {
      frontmatter {
        templateKey
        metaDescription
        metaTitle
        body
      }
    }
  }
}
`
const Privacy = ({ data }) => {
    const bodyData = data?.allMdx?.nodes[0]?.frontmatter.body
  return (
    <Layout line={LineData}>
        <h2
            className="
            font-medium
            text-2xl
            sm:text-[70px]
            sm:leading-[1.4]
            mb-8
            "
        >Privacy Policy
        </h2>
        <MarkdownContent 
            content={bodyData} 
            className="
            prose prose-base 
            sm:prose-xl 
            max-w-[950px]
            mb-8" 
        />
    </Layout>
  )
}



export default Privacy


